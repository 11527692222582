<template>
  <div class="title-steps">
    <div
      v-for="(item, index) in stageList"
      :key="index"
      :class="item.className"
    >
      <div v-if="index !== 0" class="mid-steps-triangle" />
      <div class="icon-box">
        <img :src="item.icon" />
        {{ item.label }}
      </div>
      <div v-if="index !== 4" class="steps-triangle" />
    </div>
  </div>
</template>
<script>
import icon_choose from "@/common/imgs/consultation/icon-choose.png";
import icon_default from "@/common/imgs/consultation/icon_default.png";
import icon_done from "@/common/imgs/consultation/icon_done.png";

export default {
  props: {
    detail: {
      required: true,
      immediate: true
    }
  },
  data() {
    return {
      stageList: [
        {
          label: this.$t("blzx.toAcc"),
          icon: icon_default,
          className: ""
        },
        {
          label: this.$t("blzx.inPro"),
          icon: icon_default,
          className: ""
        },
        {
          label: this.$t("blzx.toCom"),
          icon: icon_default,
          className: ""
        },
        {
          label: this.$t("blzx.toCom"),
          icon: icon_default,
          className: ""
        },
        {
          label: this.$t("blzx.toEva"),
          icon: icon_default,
          className: ""
        }
      ],
      statusMap: {
        in_process: [this.$t("blzx.toAcc"), this.$t("blzx.inPro")],
        re_process: [this.$t("blzx.toAcc"), this.$t("blzx.inPro")],
        to_feedback: [this.$t("blzx.toAcc"), this.$t("blzx.toFeed")],
        re_feedback: [this.$t("blzx.toAcc"), this.$t("blzx.toFeed")],
        to_complete: [
          this.$t("blzx.accepted"),
          this.$t("blzx.processed"),
          this.$t("blzx.toCom")
        ],
        to_close: [
          this.$t("blzx.accepted"),
          this.$t("blzx.processed"),
          this.$t("blzx.confirmed")
        ],
        closed: [
          this.$t("blzx.accepted"),
          this.$t("blzx.processed"),
          this.$t("blzx.confirmed"),
          this.$t("blzx.closed"),
          this.$t("blzx.toEva")
        ],
        evaluate: [
          this.$t("blzx.accepted"),
          this.$t("blzx.Processed"),
          this.$t("blzx.confirmed"),
          this.$t("blzx.closed"),
          this.$t("blzx.evaluated"),
          ""
        ]
      }
    };
  },
  watch: {
    detail: {
      handler(newDetail) {
        this.stageList = [
          {
            label: this.$t("blzx.toAcc"),
            icon: icon_default,
            className: ""
          },
          {
            label: this.$t("blzx.inPro"),
            icon: icon_default,
            className: ""
          },
          {
            label: this.$t("blzx.toCom"),
            icon: icon_default,
            className: ""
          },
          {
            label: this.$t("blzx.toCom"),
            icon: icon_default,
            className: ""
          },
          {
            label: this.$t("blzx.toEva"),
            icon: icon_default,
            className: ""
          }
        ];
        const status =
          newDetail.evaluationStatus === "2" ? "evaluate" : newDetail.status;
        const actions = this.statusMap[status];
        if (actions) {
          actions.forEach((action, index) => {
            if (index === actions.length - 1) {
              this.updateListProperties(
                index,
                action,
                icon_choose,
                "choose-box"
              );
            } else {
              this.updateListProperties(index, action, icon_done, "done-box");
            }
          });
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    updateListProperties(index, label, icon, className) {
      if (index !== 5) {
        const _list = [...this.stageList];
        _list[index].label = label;
        _list[index].icon = icon;
        _list[index].className = className;
        this.stageList = _list;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.title-steps {
  overflow: hidden;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  font-size: 16px;
  display: flex;
  width: 100%;
  background-color: #fff;
  height: 52px;

  img {
    display: inline-block;
    width: 18px;
    height: 18px;
  }

  > div {
    width: 19%;
    position: relative;
    height: 51px;
    background-color: #f7f7f7;
    text-align: center;
    align-items: center;
    justify-items: center;
    line-height: 52px;
    margin-left: 25px;
    color: #999;

    .mid-steps-triangle {
      position: absolute;
      left: -24px;
      top: -1px;
      border-top: 26px solid #f7f7f7;
      border-left: 26px solid transparent;
      border-bottom: 26px solid #f7f7f7;
    }

    .steps-triangle {
      position: absolute;
      right: -23px;
      top: 0;
      border-top: 26px solid transparent;
      border-left: 26px solid #f7f7f7;
      border-bottom: 26px solid transparent;
    }
  }

  > div:first-child {
    margin: 0;

    .steps-triangle {
      position: absolute;
      right: -24px;
      top: 0;
      border-top: 26px solid transparent;
      border-left: 26px solid #f7f7f7;
      border-bottom: 26px solid transparent;
    }
  }
}

.done-box {
  background-color: $main_theme_color !important;
  color: #fff !important;

  .steps-triangle {
    position: absolute;
    right: -24px;
    top: 0;
    border-top: 26px solid transparent;
    border-left: 26px solid $main_theme_color !important;
    border-bottom: 26px solid transparent;
  }

  .mid-steps-triangle {
    position: absolute;
    left: -24px;
    top: 0;
    border-top: 26px solid $main_theme_color !important;
    border-left: 26px solid transparent;
    border-bottom: 26px solid $main_theme_color !important;
  }
}

.choose-box {
  background-color: #f7f7f7 !important;
  color: $main_theme_color !important;

  .steps-triangle {
    position: absolute;
    right: -24px;
    top: 0;
    border-top: 26px solid transparent !important;
    border-left: 26px solid #f7f7f7 !important;
    border-bottom: 26px solid transparent !important;
  }

  .mid-steps-triangle {
    position: absolute;
    left: -24px;
    top: 0;
    border-top: 26px solid #f7f7f7 !important;
    border-left: 26px solid transparent !important;
    border-bottom: 26px solid #f7f7f7 !important;
  }
}

.icon-box {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;

  > img {
    display: inline-block;
    margin-bottom: -1px;
    margin-right: 4px;
  }
}
</style>
